import styles from '../styles/Home.module.css'
import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@material-ui/data-grid';
import Drawer from '@material-ui/core/Drawer';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useDispatch, useSelector } from 'react-redux'
import { actions as RedeemsActions, selectors as RedeemsSelectors } from '../store/slices/redeem'
import Button from '@material-ui/core/Button';
import { actions as AuthActions, selectors as AuthSelectors } from '../store/slices/auth'

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // root: {
    //   display: 'flex',
    // },
    appBar: {
      position: 'absolute',
      top: 0, left: 240, right: 0, height: 64,
      // width: `calc(100% - ${drawerWidth}px)`,
      // marginLeft: drawerWidth,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
  }),
);

export const SiteAppBar = ({assignMulti}) => {

  const classes = useStyles();
  const dispatch = useDispatch()

  const logout =  (e) =>{
    dispatch(AuthActions.willLogoutUser());
  }

  return (
    <div className={classes.appBar}>
      <AppBar position="static">
        <Toolbar>
          <div className="w-full">
            <Typography className="float-left" variant="h6" noWrap>
              Dashboard
          </Typography>
            <Box className="float-left ml-10">
              <Button onClick={assignMulti} variant="contained" color="secondary">Assign Multi</Button>
            </Box>
            <Box className="float-left ml-10">
              <Button onClick={()=>{dispatch(RedeemsActions.willDownloadRedeems())}} variant="outlined" color="inherit">Export Redeems</Button>
            </Box>
          </div>
          <Button color="inherit" onClick={logout}>Logout</Button>
        </Toolbar>
      </AppBar >
    </div>
  )
}