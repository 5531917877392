import { call, put, takeLatest, select, fork } from 'redux-saga/effects'
import { actions as RedeemActions, selectors as RedeemSelectors } from '../slices/redeem'
import * as RedeemApi from '../api/redeem'
import { actions as RedeemsActions, selectors as RedeemsSelectors } from '../slices/redeem'
import { actions as UIActions, selectors as UISelectors } from '../slices/ui';

export function* sagas() {
  yield takeLatest(RedeemActions.willListRedeems.type, willListRedeems);
  yield takeLatest(RedeemActions.willListTemplates.type, willListTemplates);
  yield takeLatest(RedeemActions.willUpdateTemplate.type, willUpdateTemplate);
  yield takeLatest(RedeemActions.willMintAsset.type, willMintAsset);
  yield takeLatest(RedeemActions.willAssignRedeems.type, willAssignRedeems);
  yield takeLatest(RedeemActions.willCheckMint.type, willCheckMint);
  yield takeLatest(RedeemActions.willDownloadRedeems.type, willDownloadRedeems);
  yield takeLatest(RedeemActions.willShowMetadata.type, willShowMetadata);
  // yield call(willListTemplates, {});
}

function* willListRedeems(action: any) {
  const result = yield call(RedeemApi.listRedeems);
  console.log('with result: ', result);
  yield put(RedeemActions.didListRedeems(result));
}

function* willListTemplates(action: any) {
  const result = yield call(RedeemApi.listTemplates);
  console.log('with result: ', result);
  yield put(RedeemActions.didListTemplates(result));
}

function* willUpdateTemplate(action: any) {
  const { project, name, metadata } = action.payload
  console.log('payload: ', action.payload)
  const result = yield call(RedeemApi.updateTemplate, project, name, metadata);
  console.log('with result', result);
  yield put(RedeemActions.didUpdateTemplate({ project, name, metadata }));
}

function* willMintAsset(action: any) {
  const { project, name, metadata, template, address, redeemCode } = action.payload

  // const selectedTemplate = yield select(RedeemSelectors.getTemplate, template)
  // console.log('with selectedTemplate: ', selectedTemplate);
  yield put(UIActions.willShowWaiting())

  const result = yield call(RedeemApi.mintAsset, action.payload);
  yield call(willListRedeems, {});
  yield put(UIActions.willHideWaiting())
}

function* willAssignRedeems(action: any) {
  console.log('with willAssignRedeems: ', action);

  //spit redeems in rows
  yield put(UIActions.willShowWaiting())
  const rows = action.payload.redeems.split('\n');
  console.log('with rows: ', rows);
  
  const redeems: any = [];
  for(let i = 0; i < rows.length; i++){
    const chunks: any = rows[i].split(":");
    const redeemData = {redeem: chunks[0], address: chunks[1]};
    redeems.push(redeemData)
  }
  const result = yield call(RedeemApi.assignRedeems, action.payload.project, redeems);
  console.log('with result: ', result);
  yield call(willListRedeems, {});
  yield put(UIActions.willHideWaiting())

}

function* willCheckMint(action: any) {
  console.log('in willCheckMint')
  const result = yield call(RedeemApi.checkTransaction, action.payload.project, action.payload.redeemCode);
  console.log('with result: ', result);
  if(result.redeemCode === null){
    yield put(RedeemActions.didCheckMintWithFail(action.payload));
  }
  yield call(willListRedeems, {});  
}

function* willDownloadRedeems(action: any) {
  const redeems = yield select(RedeemsSelectors.getRedeems);
  const result = yield call(RedeemApi.willDownloadRedeems, redeems);
}

function* willShowMetadata(action: any) {
  const result = yield call(RedeemApi.willDownloadMetadata, action.payload.cid);
  yield put(RedeemActions.didShowMetadata(result));
}