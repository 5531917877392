import { SiteDrawer } from '../components/drawer'
import { SiteAppBar } from '../components/appbar'

export const DrawerLayout = ({ children, assignMulti }: any) => {
  console.log('with component: ', children)
  return (
    <>
      <SiteDrawer></SiteDrawer>
      <SiteAppBar assignMulti={assignMulti}></SiteAppBar>
      <div style={{ position: 'absolute', left: 250, right: 10, top: 80, bottom: 10 }}>
        {children}
      </div>
    </>
  )
}