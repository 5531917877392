// import styles from '../styles/Home.module.css'
import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@material-ui/data-grid';
import Drawer from '@material-ui/core/Drawer';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useDispatch, useSelector } from 'react-redux'
import { actions as RedeemsActions, selectors as RedeemsSelectors } from '../store/slices/redeem'
import { actions as IPFSActions, selectors as IPFSSelectors } from '../store/slices/ipfs'
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Description from '@material-ui/icons/Description';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { Redeem } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CircularProgress from '@material-ui/core/CircularProgress';

export interface WaitingDialogProps {
  open: boolean;
  // onClose: (value: string) => void;
  // selectedValue: any;
  // children: any;
}

export const WaitingDialog = (props: WaitingDialogProps) => {

  // const classes = useStyles();
  const { open } = props;
  // const { onClose, selectedValue, open } = props;
  const [value, setValue] = React.useState({} as any);
  const dispatch = useDispatch();
  const handleClose = () => {
    // onClose(selectedValue);
  };
  // console.log('selectedValues: ', selectedValue)

  const validationSchema = yup.object({
    redeems: yup
      .string()
      .required('Redeeems is required')
  });

  const formik = useFormik({
    initialValues: {
      redeems: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log('in onSubmit with ', values);
      // alert(JSON.stringify(values, null, 2));
      dispatch(RedeemsActions.willAssignRedeems({ project: 'frankstudio', redeems: values.redeems }));


    },
  });

  return (
    <Dialog maxWidth="sm" fullWidth onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        <div className="text-center">Waiting</div>
      </DialogTitle>
      <Box className="flex mb-4 text-center w-full items-center content-center">
        <Box className="m-auto flex w-10">
          <CircularProgress color="secondary" />

        </Box>
      </Box>
    </Dialog>
  )
}